import React from 'react'

const Productcard = (props) => {

  return (
    <>
     <div className="product-box mt-5">
          <div className="product-box-img mb-5">
            <img src={props?.productData?.imageurl} className="img-fluid" />
          </div>
          <h4 className="mb-3">{props?.productData?.title}</h4>
          <button type="button" className="btn btn-outline-warning">
            Order Now
          </button>
        </div>
    </>
  )
}

export default Productcard
