
import React, { useEffect, useState } from "react";
import { getUser } from "../../Common/css/api-instans/api-instan";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner, Toast, Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";

const DeleteAccount = () => {
  const [error, setError] = useState(null);
  const [contact, setContact] = useState(null);
  const [links, setLinks] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [formValues, setFormValues] = useState(null); // State to hold the form values for deletion
  const token = useSelector((state) => state?.token);

  const BASE_URL = process.env.REACT_APP_API_URL;

  const fetchData = async () => {
    try {
      const userData = await getUser(
        "websitecontentsql/get_websitecontent",
        { sectiontype: "Contact page" },
        token
      );
      userData?.data?.forEach((item) => {
        switch (item.sectiontype) {
          case "Contact page":
            setContact(item);
            break;
          case "link":
            setLinks((prevData) => {
              if (
                !prevData.find(
                  (dataItem) =>
                    dataItem.websitecontentid === item.websitecontentid
                )
              ) {
                return [...prevData, item];
              }
              return prevData;
            });
            break;
          default:
            break;
        }
      });
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      userType: "",
      email: "",
    },
    validationSchema: Yup.object({
      userType: Yup.string()
        .oneOf(["USER", "CANTEEN", "OWNER"], "Invalid user type")
        .required("User type is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setFormValues(values);
      setShowModal(true);
    },
  });

  const handleConfirmDelete = async () => {
    setSubmitLoading(true);
    try {
      const response = await getUser(
        `${BASE_URL}websitecontentsql/delete_user`,
        formValues, // Use the stored form values
        token // Pass the token
      );

      formik.resetForm();
      if (response.issuccess) {
        setToastMessage("Account deleted successfully!");
        setToastType("success");
      } else {
        setToastMessage(
          response.status === "101"
            ? response.message || "Record not found!"
            : "Failed to delete account."
        );
        setToastType("error");
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      setToastMessage("An error occurred while deleting the account.");
      setToastType("error");
    } finally {
      setSubmitLoading(false);
      setShowModal(false); // Close the modal after submission
      setShowToast(true);

      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  };

  return (
    <>
      <div className="container">
        <div className="banner-sec">
          <div className="row g-3">
            <div className="col-lg-6">
              <div className="banner-details">
                <div className="banner-info">
                  <h1 className="mb-0">Delete Account</h1>
                  <p className="mt-4 mb-4">
                    We're here to assist with account deletion. Please note this
                    action is permanent, and all data will be erased. Contact
                    support for help if needed.
                  </p>
                  <div className="banner-app-btn mt-2">
                    {links.length > 0 &&
                      links.map((item, index) => (
                        <a key={index} href={item?.link} className="">
                          <img
                            src={item.imageurl}
                            className="img-fluid me-3"
                            alt={`link-${index}`}
                          />
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-img text-center">
                <img
                  src={contact?.imageurl}
                  className="img-fluid"
                  alt="contact-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-box">
        {contact?.title && (
          <div className="container">
            <div className="row">
              <h4 className="text-center">We'd love to hear from you!</h4>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="delete-account-form"
            >
              <div className="row input-container">
                <div className="col-md-12 col-sm-12 mb-4">
                  <div className="form-group">
                    <label htmlFor="userType" className="form-label">
                      User Type
                    </label>
                    <select
                      id="userType"
                      name="userType"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.userType}
                      required
                    >
                      <option value="" disabled>
                        Select User Type
                      </option>
                      <option value="OWNER">Owner</option>
                      <option value="CANTEEN">Canteen</option>
                      <option value="USER">User</option>
                    </select>
                    {formik.touched.userType && formik.errors.userType && (
                      <div className="error-message mt-2 text-danger">
                        {formik.errors.userType}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12 col-sm-12 mb-4">
                  <div className="form-group">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      required
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="error-message mt-2 text-danger">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-danger btn-lg"
                    disabled={submitLoading}
                  >
                    {submitLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Delete Account"
                    )}
                  </button>
                  <Toast
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    className={` ${
                      toastType === "error"
                        ? "bg-success text-white"
                        : "bg-success text-white"
                    } position-relative m-3 `}
                    style={{
                      marginTop: "10px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "fit-content",
                      justifySelf: "center",
                    }}
                  >
                    <Toast.Body>{toastMessage}</Toast.Body>
                  </Toast>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>

      {/* <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          className={`${
            toastType === "error" ? "bg-danger text-white" : "bg-success text-white"
          } position-fixed top-0 start-50% translate-middle-x m-3`}
          style={{ left: "50%", transform: "translateX(-50%)" }}
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast> */}

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Account Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete your account? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAccount;
